import { Video } from '@grandstand/presentation-models'
export const getVideoIsLive = (video: Video) => {
  const now = new Date().getTime()
  const start = new Date(video?.start_date ?? now).getTime()
  const end = new Date(video?.end_date ?? now).getTime()
  return now > start && now < end
}

export const getVideoIsUpcoming = (video?: Video) => {
  if (!video) {
    return false
  }
  const now = new Date().getTime()
  const start = new Date(video?.start_date ?? now).getTime()
  return now < start
}
