import { DetailPanelContext } from '@/contexts/DetailPanelContext'
import { button } from '@/styles/fonts'
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { useContext } from 'react'
import styled from 'styled-components'
import PlayIcon from './play-icon'

type ButtonTheme = 'default' | 'live'
type ButtonStyleProps = {
  focused: boolean
  theme?: ButtonTheme
}

const bgColorForButton = ({ theme, focused }: ButtonStyleProps) => {
  if (!focused) {
    return '--button'
  }
  if (theme === 'live') {
    return '--live'
  }
  return '--button-focus'
}

const textColorForButton = ({ theme, focused }: ButtonStyleProps) => {
  if (!focused) {
    return '--on-button-dark'
  }
  if (theme === 'live') {
    return '--white'
  }
  return '--on-button-focus'
}

const WatchButtonStyle = styled.button<ButtonStyleProps>`
  ${button}
  position: relative;
  text-transform: capitalize;
  background-color: var(${bgColorForButton});
  color: var(${textColorForButton});
  padding: 32px;
  border-radius: 4px;
  transition: background-color 150ms, color 150ms;
  margin-top: 32px;
  width: 476px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  outline: 0;
  border: none;
  box-shadow: none;
  svg {
    &,
    path {
      fill: var(${textColorForButton});
      stroke: var(${textColorForButton});
    }
  }
`

export type TDetailPanelButtonProps = {
  focusKey?: string
  buttonTitle: string
  url: string
  videoId?: string
  onEnterPress: () => void
  axeLabel?: string
  hideIcon?: boolean
  theme?: ButtonTheme
}
export default function DetailPanelButton({
  focusKey,
  buttonTitle,
  onEnterPress,
  axeLabel,
  hideIcon,
  theme,
}: TDetailPanelButtonProps) {
  const { hideDetailPanel } = useContext(DetailPanelContext)
  const otherFocusKey = focusKey === 'TOP_BUTTON' ? 'BOTTOM_BUTTON' : 'TOP_BUTTON'
  const { ref, focused, setFocus } = useFocusable({
    onArrowPress: (direction: string) => {
      switch (direction) {
        case 'left':
          hideDetailPanel()
          break
        case 'up':
        case 'down':
          setFocus(otherFocusKey)
          break
      }
      return false
    },
    focusKey,
    onEnterPress,
  })

  return (
    <WatchButtonStyle ref={ref} focused={focused} aria-label={axeLabel} theme={theme ?? 'default'}>
      {!hideIcon ? <PlayIcon /> : <></>}
      {buttonTitle}
    </WatchButtonStyle>
  )
}
