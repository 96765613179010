import DetailPanel from '@/components/Modal/DetailPanel'
import { textForLabel } from '@/components/grandstand/Label'
import { isMatchupCard, isVideoCard } from '@grandstand-web/bally-web-shared/src/utils/entitlementUtils'
import { getVideoIsLive, getVideoIsUpcoming } from '@grandstand-web/player/src/utils/videoStatusUtils'
import { MatchupCard, Video, VideoCard } from '@grandstand/presentation-models'
import { useRouter } from 'next/router'
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { UiContext } from './UiContext'

export type DetailPanelCard = VideoCard | MatchupCard

export type Panel = {
  card?: DetailPanelCard
  video?: Video
  title?: string
  description?: string
  status?: string
  captions?: string[]
  url?: string
  isLive?: boolean
  isUpcoming?: boolean
}

interface DetailPanelState {
  isDetailPanelShowing: boolean
  showDetailPanel: (card: DetailPanelCard, contentUrl: string, sgsPurchaseAvailable?: boolean) => void
  hideDetailPanel: () => void
  videoObject: Panel
  videoId?: string
  requiresCouchRights: boolean
  contentUrl?: string
  sgsPurchaseAvailable?: boolean
}

const toValidString = (str?: string) => (str && str?.length > 0 ? str : undefined)

export const DetailPanelContext = createContext<DetailPanelState>({
  isDetailPanelShowing: false,
  showDetailPanel: () => {},
  hideDetailPanel: () => {},
  videoObject: {},
  videoId: undefined,
  requiresCouchRights: false,
  contentUrl: undefined,
  sgsPurchaseAvailable: undefined,
})

export const DetailPanelReference = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const { isDetailPanelShowing, setIsDetailPanelShowing } = useContext(UiContext)

  const [requiresCouchRights, setRequiresCouchRights] = useState(false)
  const [videoObject, setVideoObject] = useState<Panel>({})
  const [videoId, setVideoId] = useState<string | undefined>(undefined)
  const [contentUrl, setContentUrl] = useState<string | undefined>(undefined)
  const [sgsPurchaseAvailable, setSgsPurchaseAvailable] = useState<boolean | undefined>(undefined)

  const hideDetailPanel = () => {
    setIsDetailPanelShowing(false)
    setVideoId(undefined)
    setContentUrl(undefined)
    setRequiresCouchRights(false)
    setVideoObject({})
  }

  const showDetailPanelForVideoCard = (card: VideoCard, contentUrl: string, sgsPurchaseAvailable?: boolean) => {
    const video = card.content.video
    if (!video) {
      hideDetailPanel()
      return
    }
    const isLive = getVideoIsLive(video)
    const isUpcoming = getVideoIsUpcoming(video)
    const isVOD = !isLive && !isUpcoming
    if (isVOD) {
      hideDetailPanel()
      return router.push(contentUrl)
    }

    const { id, status } = video
    const title = textForLabel(card.content.title_label)
    const description = toValidString(textForLabel(card.content.description_label) ?? video.description)
    const captions = card.content.caption_labels?.map((label) => textForLabel(label)) ?? []
    const next: Panel = {
      card,
      video,
      title,
      description,
      captions,
      status: isLive ? 'LIVE' : status === 'upcoming' ? 'UPCOMING' : 'REPLAY',
      isLive,
      isUpcoming,
      url: contentUrl,
    }

    setSgsPurchaseAvailable(sgsPurchaseAvailable)
    setVideoId(id)
    setContentUrl(contentUrl)
    setRequiresCouchRights(video.requires_couch_rights)
    setVideoObject(next)
    setIsDetailPanelShowing(true)
  }

  const showDetailPanelForMatchupCard = (card: MatchupCard, contentUrl: string, sgsPurchaseAvailable?: boolean) => {
    const video = card.content.video
    if (!video) {
      hideDetailPanel()
      return
    }
    const isLive = getVideoIsLive(video)
    const isUpcoming = getVideoIsUpcoming(video)
    const isVOD = !isLive && !isUpcoming
    if (isVOD) {
      hideDetailPanel()
      return router.push(contentUrl)
    }

    const { id, status } = video
    const title = video.title
    const description = toValidString(textForLabel(card.content.description_label) ?? video.description)
    const captions: string[] = []
    const next: Panel = {
      card,
      video,
      title,
      description,
      captions,
      status: isLive ? 'LIVE' : status === 'upcoming' ? 'UPCOMING' : 'REPLAY',
      isLive,
      isUpcoming,
      url: contentUrl,
    }

    setSgsPurchaseAvailable(sgsPurchaseAvailable)
    setVideoId(id)
    setContentUrl(contentUrl)
    setRequiresCouchRights(video.requires_couch_rights)
    setVideoObject(next)
    setIsDetailPanelShowing(true)
  }

  const showDetailPanel = (card: DetailPanelCard, contentUrl: string, sgsPurchaseAvailable?: boolean) => {
    if (isVideoCard(card)) {
      showDetailPanelForVideoCard(card, contentUrl, sgsPurchaseAvailable)
    }
    if (isMatchupCard(card)) {
      showDetailPanelForMatchupCard(card, contentUrl, sgsPurchaseAvailable)
    }
  }

  useEffect(() => {
    const isHomePage = router.pathname.indexOf('/home') > -1

    if (isDetailPanelShowing && !isHomePage) {
      setIsDetailPanelShowing(false)
    }
  }, [isDetailPanelShowing, router.pathname, setIsDetailPanelShowing])

  return (
    <DetailPanelContext.Provider
      value={{
        sgsPurchaseAvailable,
        isDetailPanelShowing,
        showDetailPanel,
        hideDetailPanel,
        videoObject,
        videoId: videoId,
        requiresCouchRights,
        contentUrl,
      }}
    >
      {children}
      {isDetailPanelShowing ? <DetailPanel /> : null}
    </DetailPanelContext.Provider>
  )
}
